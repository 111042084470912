import { useEffect, useContext, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";

import ProgressBar from "react-bootstrap/ProgressBar";

import Icon from "@mdi/react";
import {
  mdiAccountCancelOutline,
  mdiTimerPlayOutline,
  mdiCancel,
  mdiMedalOutline,
} from "@mdi/js";

import AppContext from "../app-provider";
import UserContext from "../user-provider";

function Znamese() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [data, setData] = useState({});
  const [state, setState] = useState("ready");

  const [agree, setAgree] = useState();
  const [answer, setAnswer] = useState();

  /* eslint-disable */
  const interval = useRef();

  useEffect(() => {
    loadData({ setState, setData, userContext });
    interval.current = setInterval(
      () => loadData({ setState, setData, userContext }),
      2000
    );
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (data.userAnswerList || data.endTs) {
      clearInterval(interval.current);
    }
  }, [data.userAnswerList, data.endTs]);

  useEffect(() => {
    setAgree(undefined);
    setAnswer("");
  }, [data.questionIndex]);

  useEffect(() => {
    const element = document.getElementById("favicon");
    element.setAttribute("href", "/znamese.ico");
    const elementTitle = document.getElementById("title");
    elementTitle.replaceChildren("Známe se?");
    appContext.setApp("Tipni si - známe se?");
    appContext.setAppCode("znamese");
    appContext.setBgColor("#E3B9FF");
  }, []);
  /* eslint-enable */

  return (
    <div className={"myContainer"}>
      {getChildren({
        userContext,
        data,
        interval,
        state,
        setData,
        setState,
        agree,
        answer,
        setAgree,
        setAnswer,
      })}
    </div>
  );
}

function getChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  agree,
  answer,
  setAgree,
  setAnswer,
}) {
  if (userContext.user.userType === "admin") {
    return getAdminChildren({
      data,
      interval,
      state,
      setData,
      setState,
    });
  } else if (userContext.user.userType === "user") {
    return getUserChildren({
      userContext,
      data,
      interval,
      state,
      setData,
      setState,
      agree,
      answer,
      setAgree,
      setAnswer,
    });
  } else {
    return (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <div>
          <Icon
            style={{ color: "#dc3545" }}
            path={mdiAccountCancelOutline}
            size={6}
          />
        </div>
        <div>{"přihlaste se prosím"}</div>
      </div>
    );
  }
}

function getAdminChildren({ data, interval, state, setData, setState }) {
  let children;
  if (!data.startTs) {
    children = (
      <div
        style={{ display: "block", textAlign: "center", paddingTop: "64px" }}
      >
        <img src="/znamese-link.png" alt="https://www.celoskovi.eu/znamese" />
        <div style={{ fontSize: "32px" }}>https://www.celoskovi.eu/znamese</div>
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Pojďme si zodpovědět pár lechtivých otázek a tipnout,
          <br />
          jak odpovídají ostatní. Je to plně anonymní.
        </div>
        <Button
          variant="success"
          size="sm"
          onClick={() => start({ data, state, setData, setState })}
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          Začít
        </Button>
      </div>
    );
  } else if (data.startTs && !data.endTs) {
    const startTime = new Date(data.currentQuestion.startTs).getTime();
    const currentTime = new Date(data.currentQuestion.currentTs).getTime();
    const difference = startTime + 18000 - currentTime;
    const progress = difference > 0 ? (difference / 20000) * 100 : 0;

    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "32px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div
          style={{
            fontSize: "40px",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          {data.questionList[data.currentQuestion.question].question}
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button
            variant={"primary"}
            size="sm"
            onClick={() => {
              nextQuestion({ interval, setState, setData });
            }}
          >
            Další otázka
          </Button>
          <Button
            size={"sm"}
            variant={"danger"}
            onClick={() => clear({ setState, setData })}
          >
            <Icon size={0.7} path={mdiCancel} /> Clear
          </Button>
        </div>
      </div>
    );
  } else {
    const userMap = {};
    const answerMap = {};
    data.answerList.forEach((answer) => {
      if (!answerMap[answer.question]) answerMap[answer.question] = [];
      answerMap[answer.question].push({
        userName: answer.userName,
        answer: answer.answer,
      });
      if (userMap[answer.userName]) userMap[answer.userName] = 0;
    });
    for (let i = 0; i < data.questionList.length; i++) {
      const trueAnswerCount = data.trueCountMap[i] || 0;
      const userAnswerList = answerMap[i] || [];
      let difference;
      if (userAnswerList.length)
        difference = Math.abs(trueAnswerCount - userAnswerList[0].answer);
      userAnswerList.forEach((userAnswer) => {
        if (Math.abs(trueAnswerCount - userAnswer.answer) < difference) {
          difference = Math.abs(trueAnswerCount - userAnswer.answer);
        }
      });
      if (answerMap[i]?.length)
        answerMap[i] = answerMap[i].filter(
          (userAnswer) =>
            Math.abs(trueAnswerCount - userAnswer.answer) === difference
        );
    }

    const resultMap = {};
    for (let key in answerMap) {
      const itemList = answerMap[key] || [];
      itemList.forEach((item) => {
        if (!resultMap[item.userName]) resultMap[item.userName] = 0;
        resultMap[item.userName] += 1;
      });
    }

    const resultList = [];
    for (let key in resultMap) {
      resultList.push({ userName: key, value: resultMap[key] });
    }
    resultList.sort((a, b) => {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      else return 0;
    });
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <Card
          style={{
            width: "100%",
            marginBottom: "16px",
            boxShadow: "4px 8px 4px gold",
          }}
        >
          <Card.Header>
            <Icon
              style={{
                color: "gold",
                marginRight: "8px",
              }}
              path={mdiMedalOutline}
              size={2}
            />
            <span style={{ fontSize: "32px" }}>Výsledek</span>
          </Card.Header>
          <ListGroup className="list-group-flush">
            {resultList.map((result) => {
              return (
                <ListGroup.Item style={{ fontSize: "24px" }}>
                  {result.userName} - {result.value}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button size={"sm"} onClick={() => clear({ setState, setData })}>
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            paddingTop: "64px",
          }}
        >
          {data.questionList.map((question, index) => {
            return (
              <div key={index}>
                <Card
                  style={{
                    marginBottom: "16px",
                    boxShadow: "4px 8px 8px #aaaaaa",
                  }}
                >
                  <Card.Header
                    style={{ fontSize: "24px", background: "#E3B9FF" }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 40px",
                      }}
                    >
                      <div>{question.question}</div>
                      <div style={{ textAlign: "right" }}>
                        {data.trueCountMap[index]}
                      </div>
                    </div>
                  </Card.Header>
                  <ListGroup className="list-group-flush">
                    {answerMap[index]?.map((result) => {
                      return (
                        <ListGroup.Item style={{ fontSize: "24px" }}>
                          {result.userName} - {result.answer}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return children;
}

function getUserChildren({
  userContext,
  data,
  setData,
  setState,
  agree,
  answer,
  setAgree,
  setAnswer,
}) {
  let children;
  if (!data.question && !data.userAnswerList) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <Icon size={5} path={mdiTimerPlayOutline} />
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Kvíz ještě nezačal, ještě chvíli vydrž.
        </div>
      </div>
    );
  } else if (data.question) {
    const startTime = new Date(data.startTs).getTime();
    const currentTime = new Date(data.currentTs).getTime();
    const difference = startTime + 18000 - currentTime;
    const progress = difference > 0 ? (difference / 20000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <div style={{ fontSize: "28px", paddingBottom: "24px" }}>
          {data.question.question}
        </div>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "18px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div style={{ display: "block", padding: "48px 0" }}>
          <Form.Check
            checked={agree}
            label={"souhlasím"}
            style={{ width: "100%", height: "34px", marginBottom: "16px" }}
            type="switch"
            onChange={(e) => {
              setAgree(e.target.checked);
              answerQuestion({
                setState,
                setData,
                userContext,
                question: data.questionIndex,
                agree: e.target.checked,
                answer,
              });
            }}
            disabled={difference <= 0}
          />
          <Form.Control
            value={answer}
            style={{ width: "100%", height: "34px", marginBottom: "16px" }}
            type="number"
            placeholder="tipni počet souhlasů"
            onChange={(e) => {
              setAnswer(e.target.value);
              answerQuestion({
                setState,
                setData,
                userContext,
                question: data.questionIndex,
                agree,
                answer: e.target.value === "" ? null : e.target.value,
              });
            }}
            disabled={difference <= 0}
          />
        </div>
      </div>
    );
  } else {
    children = (
      <div
        style={{
          display: "block",
          paddingTop: "64px",
          textAlign: "center",
          fontSize: "40px",
        }}
      >
        hotovo, výsledek centrálně :-)
      </div>
    );
  }
  return children;
}

async function loadData({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`znamese/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        userType: userContext.user.userType,
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function clear({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`znamese/clear`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: "Celošek19751203",
        userType: "admin",
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function start({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`znamese/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function nextQuestion({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`znamese/nextQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function answerQuestion({ userContext, question, agree, answer }) {
  try {
    await fetch(`znamese/answerQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        question,
        agree,
        answer,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export default Znamese;
