import { useEffect, useContext, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";

import Icon from "@mdi/react";
import {
  mdiAccountCancelOutline,
  mdiTimerPlayOutline,
  mdiCancel,
  mdiCheckBold,
  mdiCloseThick,
  mdiMedalOutline,
} from "@mdi/js";

import AppContext from "../app-provider";
import UserContext from "../user-provider";
import { json } from "react-router-dom";

function Story() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [data, setData] = useState({});
  const [state, setState] = useState("ready");
  const [storyName, setStoryName] = useState();
  const [selectedPerson, setSelectedPerson] = useState();

  const [currentAnswerCache, setCurrentAnswerCache] = useState();

  /* eslint-disable */
  const interval = useRef();

  useEffect(() => {
    loadData({ setState, setData, userContext });
    interval.current = setInterval(
      () => loadData({ setState, setData, userContext }),
      1000
    );
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (data.userAnswerList || data.endTs) {
      clearInterval(interval.current);
    }
  }, [data.userAnswerList, data.endTs]);

  useEffect(() => {
    setCurrentAnswerCache(undefined);
  }, [data.questionIndex]);

  useEffect(() => {
    const element = document.getElementById("favicon");
    element.setAttribute("href", "/story.ico");
    const elementTitle = document.getElementById("title");
    elementTitle.replaceChildren("Divadlo");
    appContext.setApp("Divadlo");
    appContext.setAppCode("story");
    appContext.setBgColor("grey");
  }, []);
  /* eslint-enable */

  return (
    <div className={"myContainer"}>
      {getChildren({
        userContext,
        data,
        interval,
        state,
        setData,
        setState,
        currentAnswerCache,
        setCurrentAnswerCache,
        storyName,
        setStoryName,
        selectedPerson,
        setSelectedPerson,
      })}
    </div>
  );
}

function getChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  currentAnswerCache,
  setCurrentAnswerCache,
  storyName,
  setStoryName,
  selectedPerson,
  setSelectedPerson,
}) {
  if (userContext.user.userType === "admin") {
    return getAdminChildren({
      data,
      interval,
      state,
      setData,
      setState,
      storyName,
      setStoryName,
    });
  } else if (userContext.user.userType === "user") {
    return getUserChildren({
      userContext,
      data,
      interval,
      state,
      setData,
      setState,
      currentAnswerCache,
      setCurrentAnswerCache,
      selectedPerson,
      setSelectedPerson,
    });
  } else {
    return (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <div>
          <Icon
            style={{ color: "#dc3545" }}
            path={mdiAccountCancelOutline}
            size={6}
          />
        </div>
        <div>{"přihlaste se prosím"}</div>
      </div>
    );
  }
}

function getAdminChildren({
  data,
  interval,
  state,
  setData,
  setState,
  storyName,
  setStoryName,
}) {
  let children;
  if (!data.stepList) {
    children = (
      <div
        style={{ display: "block", textAlign: "center", paddingTop: "64px" }}
      >
        <img src="/story-link.png" alt="https://www.celoskovi.eu/story" />
        <div style={{ fontSize: "32px" }}>https://www.celoskovi.eu/story</div>
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Pojďme si zahrát divadlo :-).
        </div>
        <Form.Control
          style={{
            position: "absolute",
            top: "12px",
            right: "128px",
            zIndex: "2000",
            maxWidth: "150px",
            height: "34px",
          }}
          value={storyName}
          type="text"
          placeholder="zadej příběh"
          onChange={(e) => setStoryName(e.target.value)}
        />
        <Button
          variant="success"
          size="sm"
          onClick={() => start({ setState, setData, storyName })}
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          Začít
        </Button>
      </div>
    );
  } else {
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <div style={{ fontSize: "40px", paddingTop: "48px" }}>
          {data.stepList[data.step]}
        </div>
        <div
          style={{
            fontSize: "32px",
            padding: "16px 0",
            textAlign: "center",
          }}
        >
          --------- next ---------
        </div>{" "}
        <div style={{ fontSize: "40px", paddingTop: "48px" }}>
          {data.stepList[(data.step || 0) + 1]}
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button
            variant={"primary"}
            size="sm"
            onClick={() => {
              next({ interval, setState, setData });
            }}
          >
            Další krok
          </Button>
          <Button
            size={"sm"}
            variant={"danger"}
            onClick={() => clear({ setState, setData })}
          >
            <Icon size={0.7} path={mdiCancel} /> Clear
          </Button>
        </div>
      </div>
    );
  }
  return children;
}

function getUserChildren({ data, selectedPerson, setSelectedPerson }) {
  let children;
  if (!data.stepList) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <Icon size={5} path={mdiTimerPlayOutline} />
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Za chvíli si začneme hrát, ještě chvíli vydrž.
        </div>
      </div>
    );
  } else if (!selectedPerson) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        {data.personList.map((person) => {
          return (
            <div>
              <Button onClick={() => setSelectedPerson(person[0])}>
                {person[0]} - {person[1]}
              </Button>
            </div>
          );
        })}
      </div>
    );
  } else {
    children = (
      <div
        style={{ display: "block", paddingTop: "64px", textAlign: "center" }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "12px",
            zIndex: "2000",
            width: "300px",
            height: "36px",
            fontSize: "22px",
            background: "grey",
            textAlign: "right",
          }}
        >
          {selectedPerson}
        </div>
        {data.stepList[data.step]?.includes(selectedPerson) ? (
          <div
            style={{ fontSize: "40px", padding: "0 16px", textAlign: "left" }}
          >
            {data.stepList[data.step]}
          </div>
        ) : null}
        {!data.stepList[data.step]?.match(/[A-Z\s]+:/gm) ? (
          <div
            style={{
              fontSize: "40px",
              padding: "0 16px",
              textAlign: "left",
              color: "grey",
              fontStyle: "italic",
            }}
          >
            {data.stepList[data.step]}
          </div>
        ) : null}
        <div
          style={{
            fontSize: "32px",
            padding: "16px 0",
            textAlign: "center",
          }}
        >
          --------- next ---------
        </div>
        {data.stepList[(data.step || 0) + 1]?.includes(selectedPerson) ? (
          <div
            style={{ fontSize: "24px", padding: "0 16px", textAlign: "left" }}
          >
            {data.stepList[(data.step || 0) + 1]}
          </div>
        ) : null}
        {!data.stepList[(data.step || 0) + 1]?.match(/[A-Z\s]+:/gm) ? (
          <div
            style={{
              fontSize: "24px",
              padding: "0 16px",
              textAlign: "left",
              color: "grey",
              fontStyle: "italic",
            }}
          >
            {data.stepList[(data.step || 0) + 1]}
          </div>
        ) : null}
      </div>
    );
  }
  // if (!data.question && !data.userAnswerList) {
  //   children = (
  //     <div
  //       style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
  //     >
  //       <Icon size={5} path={mdiTimerPlayOutline} />
  //       <div style={{ fontSize: "32px", padding: "32px 0" }}>
  //         Kvíz ještě nezačal, ještě chvíli vydrž.
  //       </div>
  //     </div>
  //   );
  // } else if (data.question) {
  //   const startTime = new Date(data.startTs).getTime();
  //   const currentTime = new Date(data.currentTs).getTime();
  //   const difference = startTime + 28000 - currentTime;
  //   const progress = difference > 0 ? (difference / 30000) * 100 : 0;
  //   children = (
  //     <div style={{ display: "block", paddingTop: "64px" }}>
  //       <div style={{ fontSize: "28px", paddingTop: "48px" }}>
  //         {data.question}
  //       </div>
  //       {progress > 0 ? (
  //         <ProgressBar variant={"info"} now={progress} />
  //       ) : (
  //         <div
  //           style={{ color: "#dc3545", fontSize: "18px", textAlign: "center" }}
  //         >
  //           čas vypršel
  //         </div>
  //       )}
  //     </div>
  //   );
  // }
  return children;
}

async function loadData({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`story/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        userType: userContext.user.userType,
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function clear({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`story/clear`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: "Celošek19751203",
        userType: "admin",
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function start({ setState, setData, storyName }) {
  try {
    setState("pending");
    const res = await fetch(`story/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ storyName }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function next({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`story/next`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

export default Story;
