import { useEffect, useContext, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import Form from "react-bootstrap/Form";

import ProgressBar from "react-bootstrap/ProgressBar";

import Icon from "@mdi/react";
import {
  mdiAccountCancelOutline,
  mdiTimerPlayOutline,
  mdiCancel,
  mdiCheckBold,
  mdiCloseThick,
  mdiMedalOutline,
  mdiPlusMinus,
  mdiHammer,
  mdiAccountGroup,
  mdiMapMarkerRadiusOutline,
  mdiArrowUpBold,
} from "@mdi/js";

import AppContext from "../app-provider";
import UserContext from "../user-provider";

function Legenda() {
  return (
    <Card>
      <ListGroup>
        <ListGroup.Item
          style={{
            display: "grid",
            gridTemplateColumns: "40px auto",
            columnGap: "16px",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          <Icon
            size={1.5}
            path={mdiPlusMinus}
            style={{
              color: "white",
              background: "#30738D",
              borderRadius: "8px",
            }}
          />
          <div>odchylka v diakritice (s/z, v/f, i/y, a/á, ...)</div>
        </ListGroup.Item>
        <ListGroup.Item
          style={{
            display: "grid",
            gridTemplateColumns: "40px auto",
            columnGap: "16px",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          <Icon
            size={1.5}
            path={mdiHammer}
            style={{
              color: "white",
              background: "#30738D",
              borderRadius: "8px",
            }}
          />
          <div>výsledné slovo je sloveso (např. lovit)</div>
        </ListGroup.Item>
        <ListGroup.Item
          style={{
            display: "grid",
            gridTemplateColumns: "40px auto",
            columnGap: "16px",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          <Icon
            size={1.5}
            path={mdiAccountGroup}
            style={{
              color: "white",
              background: "#30738D",
              borderRadius: "8px",
            }}
          />
          <div>výsledné slovo je v množném čísle (např. surikaty)</div>
        </ListGroup.Item>
        <ListGroup.Item
          style={{
            display: "grid",
            gridTemplateColumns: "40px auto",
            columnGap: "16px",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          <Icon
            size={1.5}
            path={mdiMapMarkerRadiusOutline}
            style={{
              color: "white",
              background: "#30738D",
              borderRadius: "8px",
            }}
          />
          <div>výsledné slovo je vlastním názvem míst, města, státu, ...</div>
        </ListGroup.Item>
        <ListGroup.Item
          style={{
            display: "grid",
            gridTemplateColumns: "40px auto",
            columnGap: "16px",
            fontSize: "22px",
            alignItems: "center",
          }}
        >
          <Icon
            size={1.5}
            path={mdiArrowUpBold}
            style={{ color: "red", borderRadius: "8px" }}
          />
          <div>
            barevné šipky vyznačují buď důležitý objekt, nebo vyjadřují směr
            pohybu
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}

function Lustovky() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [data, setData] = useState({});
  const [state, setState] = useState("ready");

  const [userInputValue, setUserInputValue] = useState("");

  /* eslint-disable */
  const interval = useRef();

  useEffect(() => {
    loadData({ setState, setData, userContext });
    interval.current = setInterval(() => {
      loadData({ setState, setData, userContext });
    }, 2000);
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (data.userAnswerList || data.endTs) {
      clearInterval(interval.current);
    }
  }, [data.userAnswerList, data.endTs]);

  useEffect(() => {
    setUserInputValue("");
  }, [data.questionIndex]);

  useEffect(() => {
    const element = document.getElementById("favicon");
    element.setAttribute("href", "/lustovky.ico");
    const elementTitle = document.getElementById("title");
    elementTitle.replaceChildren("Lušťovky");
    appContext.setApp("Lušťovky");
    appContext.setAppCode("lustovky");
    appContext.setBgColor("#EF820D");
  }, []);
  /* eslint-enable */

  return (
    <div className={"myContainer"}>
      {getChildren({
        userContext,
        data,
        interval,
        state,
        setData,
        setState,
        userInputValue,
        setUserInputValue,
      })}
    </div>
  );
}

function getChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  userInputValue,
  setUserInputValue,
}) {
  if (userContext.user.userType === "admin") {
    return getAdminChildren({
      data,
      interval,
      state,
      setData,
      setState,
    });
  } else if (userContext.user.userType === "user") {
    return getUserChildren({
      userContext,
      data,
      interval,
      state,
      setData,
      setState,
      userInputValue,
      setUserInputValue,
    });
  } else {
    return (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <div>
          <Icon
            style={{ color: "#dc3545" }}
            path={mdiAccountCancelOutline}
            size={6}
          />
        </div>
        <div>{"přihlaste se prosím"}</div>
      </div>
    );
  }
}

function getAdminChildren({ data, interval, state, setData, setState }) {
  let children;
  if (!data.startTs) {
    children = (
      <div
        style={{ display: "block", textAlign: "center", paddingTop: "64px" }}
      >
        <img src="/lustovky-link.png" alt="https://www.celoskovi.eu/lustovky" />
        <div style={{ fontSize: "32px" }}>
          https://www.celoskovi.eu/lustovky
        </div>
        <div style={{ fontSize: "32px", padding: "32px 0 64px" }}>
          Uhodneš co se skrývá v obrázku?
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "auto 40%" }}>
          <div>
            <img
              style={{ maxHeight: "60vh" }}
              alt="otázka"
              src={`/lustovky-img/E01.jpg`}
            />
            <div style={{ fontSize: "24px", paddingTop: "16px" }}>
              poranit (pór a nit)
            </div>
          </div>
          {Legenda()}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 40%",
            paddingTop: "64px",
          }}
        >
          <div>
            <img
              style={{ maxHeight: "60vh" }}
              alt="otázka"
              src={`/lustovky-img/E02.jpg`}
            />
            <div style={{ fontSize: "24px", paddingTop: "16px" }}>
              páter (páteř bez háčku)
            </div>
          </div>
          {Legenda()}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 40%",
            paddingTop: "64px",
          }}
        >
          <div>
            <img
              style={{ maxHeight: "60vh" }}
              alt="otázka"
              src={`/lustovky-img/E03.jpg`}
            />
            <div style={{ fontSize: "24px", paddingTop: "16px" }}>
              Poděbrady (pod Ě brady)
            </div>
          </div>
          {Legenda()}
        </div>

        <Button
          variant="success"
          size="sm"
          onClick={() => start({ data, state, setData, setState })}
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          Začít
        </Button>
      </div>
    );
  } else if (data.startTs && !data.endTs) {
    const startTime = new Date(data.currentQuestion.startTs).getTime();
    const currentTime = new Date(data.currentQuestion.currentTs).getTime();
    const difference = startTime + 58000 - currentTime;
    const progress = difference > 0 ? (difference / 60000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "32px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div
          style={{
            fontSize: "40px",
            paddingTop: "48px",
            paddingBottom: "48px",
            display: "grid",
            gridTemplateColumns: "auto 30%",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{ maxHeight: "60vh" }}
              alt="otázka"
              src={`/lustovky-img/${data.questionList[
                data.currentQuestion.question
              ]
                .toString()
                .padStart(3, "0")}.jpg`}
            />
          </div>
          {Legenda()}
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button
            variant={"primary"}
            size="sm"
            onClick={() => {
              nextQuestion({ interval, setState, setData });
            }}
          >
            Další otázka
          </Button>
          <Button
            size="sm"
            variant={"danger"}
            onClick={() => clear({ setState, setData })}
          >
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
      </div>
    );
  } else {
    const resultMap = {};
    data.answerList.forEach((answer) => {
      const isAnswerCorrect =
        answer.answer?.toUpperCase() ===
        data.questionTemplateList[
          data.questionList[answer.question]
        ].answer.toUpperCase();
      if (!resultMap[answer.userName]) resultMap[answer.userName] = 0;
      if (isAnswerCorrect) resultMap[answer.userName] += 1;
    });
    const resultList = [];
    for (let key in resultMap) {
      resultList.push({ userName: key, value: resultMap[key] });
    }
    resultList.sort((a, b) => {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      else return 0;
    });
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <Card
          style={{
            width: "100%",
            marginBottom: "16px",
            boxShadow: "4px 8px 4px gold",
          }}
        >
          <Card.Header>
            <Icon
              style={{
                color: "gold",
                marginRight: "8px",
              }}
              path={mdiMedalOutline}
              size={2}
            />
            <span style={{ fontSize: "32px" }}>Výsledek</span>
          </Card.Header>
          <ListGroup className="list-group-flush">
            {resultList.map((result, index) => {
              return (
                <ListGroup.Item key={index} style={{ fontSize: "24px" }}>
                  {result.userName} - {result.value}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>{" "}
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button size={"sm"} onClick={() => clear({ setState, setData })}>
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
        <div style={{ display: "block", paddingTop: "64px" }}>
          {data.questionList.map((question) => {
            const questionData = data.questionTemplateList[question];
            return (
              <div key={question}>
                <Card
                  style={{
                    marginBottom: "16px",
                    boxShadow: "4px 8px 8px #aaaaaa",
                  }}
                >
                  <Card.Header>
                    <span style={{ fontSize: "24px" }}>
                      {questionData.answer} ({questionData.desc})
                    </span>
                  </Card.Header>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item
                      style={{ fontSize: "24px", textAlign: "center" }}
                    >
                      <img
                        style={{
                          maxHeight: "60vh",
                          maxWidth: "80%",
                          padding: "16px 0",
                        }}
                        alt="otázka"
                        src={`/lustovky-img/${question
                          .toString()
                          .padStart(3, "0")}.jpg`}
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return children;
}

function getUserChildren({
  data,
  userInputValue,
  setUserInputValue,
  setState,
  setData,
  userContext,
}) {
  let children;

  if (!data.question && data.question !== 0 && !data.userAnswerList) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <Icon size={5} path={mdiTimerPlayOutline} />
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Kvíz ještě nezačal, ještě chvíli vydrž.
        </div>
      </div>
    );
  } else if (data.question || data.question === 0) {
    const startTime = new Date(data.startTs).getTime();
    const currentTime = new Date(data.currentTs).getTime();
    const difference = startTime + 58000 - currentTime;
    const progress = difference > 0 ? (difference / 60000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "18px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div style={{ textAlign: "center" }}>
          <img
            style={{ maxWidth: "80vw", padding: "16px 0" }}
            alt="otázka"
            src={`/lustovky-img/${data.question
              .toString()
              .padStart(3, "0")}.jpg`}
          />
        </div>
        <Form.Control
          value={userInputValue}
          style={{ width: "100%", height: "34px", marginBottom: "16px" }}
          type="text"
          placeholder="vyplň odpověď"
          onChange={(e) => {
            setUserInputValue(e.target.value);
            answerQuestion({
              setState,
              setData,
              userContext,
              question: data.questionIndex,
              answer: e.target.value,
            });
          }}
          disabled={difference <= 0}
        />
        {Legenda()}
      </div>
    );
  } else {
    children = getUserAnswerList({ data });
  }
  return children;
}

function getUserAnswerList({ data }) {
  let correctAnswerSum = 0;
  return (
    <div style={{ display: "block", paddingTop: "64px" }}>
      {data.userAnswerList.map((question, index) => {
        if (question.correct) correctAnswerSum += 1;
        return (
          <div key={index}>
            <Card
              style={{
                marginBottom: "16px",
                boxShadow: "4px 8px 8px #aaaaaa",
              }}
            >
              <Card.Header>
                <Icon
                  style={{
                    color: question.correct ? "#75b798" : "#dc3545",
                    marginRight: "8px",
                  }}
                  path={question.correct ? mdiCheckBold : mdiCloseThick}
                  size={1}
                />
                <span style={{ fontSize: "24px" }}>
                  {question.answer} ({question.desc})
                </span>
              </Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroup.Item style={{ fontSize: "24px" }}>
                  <img
                    style={{ maxWidth: "100%", padding: "16px 0" }}
                    alt="otázka"
                    src={`/lustovky-img/${question.question
                      .toString()
                      .padStart(3, "0")}.jpg`}
                  />
                </ListGroup.Item>
                {!question.correct &&
                  (question.userAnswer ? (
                    <ListGroup.Item style={{ background: "#FF9A98" }}>
                      <i style={{ color: "grey" }}>Tvá odpověď:</i>{" "}
                      <span style={{ fontSize: "24px" }}>
                        {question.userAnswer}
                      </span>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item style={{ background: "#FF9A98" }}>
                      ---
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card>
          </div>
        );
      })}
      <div style={{ fontSize: "24px" }}>
        Tvůj výsledek: {<span>{correctAnswerSum}</span>} /{" "}
        {<span>{data.userAnswerList.length}</span>}
      </div>
    </div>
  );
}

async function loadData({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`lustovky/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        userType: userContext.user.userType,
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function clear({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`lustovky/clear`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: "Celošek19751203",
        userType: "admin",
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function start({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`lustovky/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function nextQuestion({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`lustovky/nextQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function answerQuestion({ userContext, question, answer }) {
  try {
    await fetch(`lustovky/answerQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        question,
        answer,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export default Lustovky;
