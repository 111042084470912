import { useEffect, useContext, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import ProgressBar from "react-bootstrap/ProgressBar";

import Icon from "@mdi/react";
import {
  mdiAccountCancelOutline,
  mdiTimerPlayOutline,
  mdiCancel,
  mdiCheckBold,
  mdiCloseThick,
  mdiMedalOutline,
} from "@mdi/js";

import AppContext from "../app-provider";
import UserContext from "../user-provider";

function getAdminCard({ data, hideIndex, askedCard, index }) {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          height: "36px",
          fontSize: "24px",
          textAlign: "center",
          fontWeight: "bold",
          background:
            askedCard?.miseryIndex === data.miseryIndex ? "#F2BA05" : undefined,
        }}
      >
        {askedCard?.miseryIndex === data.miseryIndex
          ? ["A", "B", "C", "D"][index]
          : ""}
      </div>
      <img
        style={{ width: "100%" }}
        src={`shit-happens-img/${(data.miseryIndex * 10)
          .toString()
          .padStart(3, "0")}.jpg`}
        alt={data.miseryIndex}
      />
      {hideIndex && (
        <div
          style={{
            position: "absolute",
            top: "80%",
            right: "20%",
            bottom: "2%",
            left: "20%",
            background: "#F2BA05",
            fontSize: "4vw",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          ???
        </div>
      )}
    </div>
  );
}

function getUserCard({ data, hideIndex, index, correctAnswer, showAnswer }) {
  console.log(correctAnswer);
  return (
    <div style={{ position: "relative" }}>
      {showAnswer && (
        <div
          style={{
            height: "36px",
            fontSize: "24px",
            textAlign: "center",
            fontWeight: "bold",
            background:
              correctAnswer && correctAnswer === index ? "#F2BA05" : undefined,
          }}
        >
          {correctAnswer === index ? ["A", "B", "C", "D"][index] : ""}
        </div>
      )}
      <img
        style={{ width: "100%" }}
        src={`shit-happens-img/${(data.miseryIndex * 10)
          .toString()
          .padStart(3, "0")}.jpg`}
        alt={data.miseryIndex}
      />
      {hideIndex && (
        <div
          style={{
            position: "absolute",
            top: "80%",
            right: "20%",
            bottom: "2%",
            left: "20%",
            background: "#F2BA05",
            fontSize: "46px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          ???
        </div>
      )}
    </div>
  );
}

function ShitHappens() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [data, setData] = useState({});
  const [state, setState] = useState("ready");

  const [currentAnswerCache, setCurrentAnswerCache] = useState();

  /* eslint-disable */
  const interval = useRef();

  useEffect(() => {
    loadData({ setState, setData, userContext });
    interval.current = setInterval(
      () => loadData({ setState, setData, userContext }),
      2000
    );
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (data.userAnswerList || data.endTs) {
      clearInterval(interval.current);
    }
  }, [data.userAnswerList, data.endTs]);

  useEffect(() => {
    setCurrentAnswerCache(undefined);
  }, [data.questionIndex]);

  useEffect(() => {
    const element = document.getElementById("favicon");
    element.setAttribute("href", "/shit-happens.ico");
    const elementTitle = document.getElementById("title");
    elementTitle.replaceChildren("Shit Happens");
    appContext.setApp("Shit Happens");
    appContext.setAppCode("shitHappens");
    appContext.setBgColor("#D4A276");
  }, []);
  /* eslint-enable */

  return (
    <div className={"myContainer"}>
      {getChildren({
        userContext,
        data,
        interval,
        state,
        setData,
        setState,
        currentAnswerCache,
        setCurrentAnswerCache,
      })}
    </div>
  );
}

function getChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  currentAnswerCache,
  setCurrentAnswerCache,
}) {
  if (userContext.user.userType === "admin") {
    return getAdminChildren({
      data,
      interval,
      state,
      setData,
      setState,
    });
  } else if (userContext.user.userType === "user") {
    return getUserChildren({
      userContext,
      data,
      interval,
      state,
      setData,
      setState,
      currentAnswerCache,
      setCurrentAnswerCache,
    });
  } else {
    return (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <div>
          <Icon
            style={{ color: "#dc3545" }}
            path={mdiAccountCancelOutline}
            size={6}
          />
        </div>
        <div>{"přihlaste se prosím"}</div>
      </div>
    );
  }
}

function getAdminChildren({ data, interval, state, setData, setState }) {
  let children;
  if (!data.startTs) {
    children = (
      <div
        style={{ display: "block", textAlign: "center", paddingTop: "64px" }}
      >
        <img
          src="/shit-happens-link.png"
          alt="https://www.celoskovi.eu/shitHappens"
        />
        <div style={{ fontSize: "32px" }}>
          https://www.celoskovi.eu/shitHappens
        </div>
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Každý občas šlápneme do sraček, pojďme zkusit najít,
          <br />
          která z uvedených sraček je horší.
        </div>
        <Button
          variant="success"
          size="sm"
          onClick={() => start({ data, state, setData, setState })}
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          Začít
        </Button>
      </div>
    );
  } else if (data.startTs && !data.endTs) {
    const startTime = new Date(data.currentQuestion.startTs).getTime();
    const currentTime = new Date(data.currentQuestion.currentTs).getTime();
    const difference = startTime + 28000 - currentTime;
    const progress = difference > 0 ? (difference / 30000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "32px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div
          style={{
            margin: "32px 0",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            columnGap: "8px",
          }}
        >
          {getAdminCard({
            data: data.questionList[data.currentQuestion.question][0],
            hideIndex: true,
          })}
          {getAdminCard({
            data: data.questionList[data.currentQuestion.question][1],
          })}
          {getAdminCard({
            data: data.questionList[data.currentQuestion.question][2],
          })}
          {getAdminCard({
            data: data.questionList[data.currentQuestion.question][3],
          })}
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button
            variant={"primary"}
            size="sm"
            onClick={() => {
              nextQuestion({ interval, setState, setData });
            }}
          >
            Další otázka
          </Button>
          <Button
            variant={"danger"}
            size="sm"
            onClick={() => {
              clear({ setState, setData });
            }}
          >
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
      </div>
    );
  } else {
    const resultMap = {};
    data.answerList.forEach((answer) => {
      const isAnswerCorrect =
        answer.answer === data.correctAnswerList[answer.question];
      if (!resultMap[answer.userName]) resultMap[answer.userName] = 0;
      if (isAnswerCorrect) resultMap[answer.userName] += 1;
    });
    const resultList = [];
    for (let key in resultMap) {
      resultList.push({ userName: key, value: resultMap[key] });
    }
    resultList.sort((a, b) => {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      else return 0;
    });
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <Card
          style={{
            width: "100%",
            marginBottom: "16px",
            boxShadow: "4px 8px 4px gold",
          }}
        >
          <Card.Header>
            <Icon
              style={{
                color: "gold",
                marginRight: "8px",
              }}
              path={mdiMedalOutline}
              size={2}
            />
            <span style={{ fontSize: "32px", fontWeight: "bold" }}>
              Výsledek
            </span>
          </Card.Header>
          <ListGroup className="list-group-flush">
            {resultList.map((result) => {
              return (
                <ListGroup.Item style={{ fontSize: "24px" }}>
                  {result.userName} - {result.value}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button size={"sm"} onClick={() => clear({ setState, setData })}>
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
        <div style={{ display: "block", paddingTop: "64px" }}>
          <div
            style={{
              margin: "8px 0 12px 0",
              height: "0",
              border: "solid 1px grey",
            }}
          />
          {data.questionList.map((question, index) => {
            const askedCard = question[0];
            return (
              <div key={index}>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "16px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    columnGap: "8px",
                  }}
                >
                  {question
                    .sort((a, b) => {
                      if (a.miseryIndex < b.miseryIndex) return -1;
                      else if (a.miseryIndex > b.miseryIndex) return 1;
                      else return 0;
                    })
                    .map((questionItem, index) => {
                      return getAdminCard({
                        data: questionItem,
                        askedCard,
                        index,
                      });
                    })}
                </div>
                <div
                  style={{
                    margin: "8px 0 12px 0",
                    height: "0",
                    border: "solid 1px grey",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return children;
}

function getUserChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  currentAnswerCache,
  setCurrentAnswerCache,
}) {
  let children;
  if (!data.question && !data.userAnswerList) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <Icon size={5} path={mdiTimerPlayOutline} />
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Kvíz ještě nezačal, ještě chvíli vydrž.
        </div>
      </div>
    );
  } else if (data.question) {
    const startTime = new Date(data.startTs).getTime();
    const currentTime = new Date(data.currentTs).getTime();
    const difference = startTime + 28000 - currentTime;
    const progress = difference > 0 ? (difference / 30000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "18px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div style={{ overflow: "auto" }}>
          <div
            style={{
              margin: "32px 0",
              display: "grid",
              gridTemplateColumns:
                "250px 48px 250px 48px 250px 48px 250px 48px",
              columnGap: "8px",
            }}
          >
            {getUserCard({
              data: data.question[0],
              hideIndex: true,
            })}
            <Button
              key={data.questionIndex + "_" + 0}
              style={{ fontSize: "28px", width: "100%" }}
              onClick={(e) => {
                answerQuestion({
                  setState,
                  setData,
                  userContext,
                  question: data.questionIndex,
                  answer: 0,
                });
                setCurrentAnswerCache(0);
                e.target.blur();
              }}
              disabled={difference <= 0}
              active={false}
              variant={currentAnswerCache === 0 ? "warning" : "dark"}
            >
              <strong>A</strong>
            </Button>
            {getUserCard({
              data: data.question[1],
            })}
            <Button
              key={data.questionIndex + "_" + 1}
              style={{ fontSize: "28px", width: "100%" }}
              onClick={(e) => {
                answerQuestion({
                  setState,
                  setData,
                  userContext,
                  question: data.questionIndex,
                  answer: 1,
                });
                setCurrentAnswerCache(1);
                e.target.blur();
              }}
              disabled={difference <= 0}
              active={false}
              variant={currentAnswerCache === 1 ? "warning" : "dark"}
            >
              <strong>B</strong>
            </Button>
            {getUserCard({
              data: data.question[2],
            })}
            <Button
              key={data.questionIndex + "_" + 2}
              style={{ fontSize: "28px", width: "100%" }}
              onClick={(e) => {
                answerQuestion({
                  setState,
                  setData,
                  userContext,
                  question: data.questionIndex,
                  answer: 2,
                });
                setCurrentAnswerCache(2);
                e.target.blur();
              }}
              disabled={difference <= 0}
              active={false}
              variant={currentAnswerCache === 2 ? "warning" : "dark"}
            >
              <strong>C</strong>
            </Button>
            {getUserCard({
              data: data.question[3],
            })}
            <Button
              key={data.questionIndex + "_" + 3}
              style={{ fontSize: "28px", width: "100%" }}
              onClick={(e) => {
                answerQuestion({
                  setState,
                  setData,
                  userContext,
                  question: data.questionIndex,
                  answer: 3,
                });
                setCurrentAnswerCache(3);
                e.target.blur();
              }}
              disabled={difference <= 0}
              active={false}
              variant={currentAnswerCache === 3 ? "warning" : "dark"}
            >
              <strong>D</strong>
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    children = getUserAnswerList({ data });
  }
  return children;
}

function getUserAnswerList({ data }) {
  let correctAnswerSum = 0;
  return (
    <div style={{ display: "block", paddingTop: "64px" }}>
      {data.userAnswerList.map((question, index) => {
        if (question.correct) correctAnswerSum += 1;
        return (
          <div key={index}>
            <Card style={{ width: "100%", marginBottom: "16px" }}>
              <Card.Header style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  style={{
                    color: question.correct ? "#75b798" : "#dc3545",
                    marginRight: "8px",
                  }}
                  path={question.correct ? mdiCheckBold : mdiCloseThick}
                  size={1}
                />
                {!question.correct && (
                  <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                    {question.userAnswer || question.userAnswer === 0
                      ? ["A", "B", "C", "D"][question.userAnswer]
                      : "---"}
                  </span>
                )}
              </Card.Header>
              <ListGroup
                className="list-group-flush"
                style={{ overflow: "auto" }}
              >
                <div
                  style={{
                    padding: "0",
                    display: "grid",
                    gridTemplateColumns: "250px 250px 250px 250px",
                    columnGap: "16px",
                  }}
                >
                  {question.questionData
                    .sort((a, b) => {
                      if (a.miseryIndex < b.miseryIndex) return -1;
                      else if (a.miseryIndex > b.miseryIndex) return 1;
                      else return 0;
                    })
                    .map((questionItem, index) => {
                      return getUserCard({
                        data: questionItem,
                        index,
                        correctAnswer: question.answer,
                        showAnswer: true,
                      });
                    })}
                </div>
              </ListGroup>
            </Card>
          </div>
        );
      })}
      <div style={{ fontSize: "24px" }}>
        Tvůj výsledek: {<span>{correctAnswerSum}</span>} /{" "}
        {<span>{data.userAnswerList.length}</span>}
      </div>
    </div>
  );
}

async function loadData({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`shitHappens/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        userType: userContext.user.userType,
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function clear({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`shitHappens/clear`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: "Celošek19751203",
        userType: "admin",
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function start({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`shitHappens/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function nextQuestion({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`shitHappens/nextQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function answerQuestion({ userContext, question, answer }) {
  try {
    await fetch(`shitHappens/answerQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        question,
        answer,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export default ShitHappens;
