import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import Celoskovi from "./celoskovi";
import Eighties from "./eighties/eighties";
import ShitHappens from "./shit-happens/shit-happens";
import Lustovky from "./lustovky/lustovky";
import Znamese from "./znamese/znamese";
import Story from "./story/story";

import { AppProvider } from "./app-provider";
import { UserProvider } from "./user-provider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <UserProvider>
      <AppProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<Celoskovi />}></Route>
            <Route path="/osmdesatky" element={<Eighties />}></Route>
            <Route path="/lustovky" element={<Lustovky />}></Route>
            <Route path="/shitHappens" element={<ShitHappens />}></Route>
            <Route path="/znamese" element={<Znamese />}></Route>
            <Route path="/story" element={<Story />}></Route>
          </Route>
        </Routes>
      </AppProvider>
    </UserProvider>
  </BrowserRouter>
);
