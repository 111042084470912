import { useEffect, useContext, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import ProgressBar from "react-bootstrap/ProgressBar";

import Icon from "@mdi/react";
import {
  mdiAccountCancelOutline,
  mdiTimerPlayOutline,
  mdiCancel,
  mdiCheckBold,
  mdiCloseThick,
  mdiMedalOutline,
} from "@mdi/js";

import AppContext from "../app-provider";
import UserContext from "../user-provider";

function Eighties() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const [data, setData] = useState({});
  const [state, setState] = useState("ready");

  const [currentAnswerCache, setCurrentAnswerCache] = useState();

  /* eslint-disable */
  const interval = useRef();

  useEffect(() => {
    loadData({ setState, setData, userContext });
    interval.current = setInterval(
      () => loadData({ setState, setData, userContext }),
      2000
    );
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (data.userAnswerList || data.endTs) {
      clearInterval(interval.current);
    }
  }, [data.userAnswerList, data.endTs]);

  useEffect(() => {
    setCurrentAnswerCache(undefined);
  }, [data.questionIndex]);

  useEffect(() => {
    const element = document.getElementById("favicon");
    element.setAttribute("href", "/80ties.ico");
    const elementTitle = document.getElementById("title");
    elementTitle.replaceChildren("80. léta");
    appContext.setApp("Kvíz - 80. léta");
    appContext.setAppCode("eighties");
    appContext.setBgColor("lightpink");
  }, []);
  /* eslint-enable */

  return (
    <div className={"myContainer"}>
      {getChildren({
        userContext,
        data,
        interval,
        state,
        setData,
        setState,
        currentAnswerCache,
        setCurrentAnswerCache,
      })}
    </div>
  );
}

function getChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  currentAnswerCache,
  setCurrentAnswerCache,
}) {
  if (userContext.user.userType === "admin") {
    return getAdminChildren({
      data,
      interval,
      state,
      setData,
      setState,
    });
  } else if (userContext.user.userType === "user") {
    return getUserChildren({
      userContext,
      data,
      interval,
      state,
      setData,
      setState,
      currentAnswerCache,
      setCurrentAnswerCache,
    });
  } else {
    return (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <div>
          <Icon
            style={{ color: "#dc3545" }}
            path={mdiAccountCancelOutline}
            size={6}
          />
        </div>
        <div>{"přihlaste se prosím"}</div>
      </div>
    );
  }
}

function getAdminChildren({ data, interval, state, setData, setState }) {
  let children;
  if (!data.startTs) {
    children = (
      <div
        style={{ display: "block", textAlign: "center", paddingTop: "64px" }}
      >
        <img
          src="/eighties-link.png"
          alt="https://www.celoskovi.eu/osmdesatky"
        />
        <div style={{ fontSize: "32px" }}>
          https://www.celoskovi.eu/osmdesatky
        </div>
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Zahrajeme si kvíz z 80. let. Má 21 otázek,
          <br />
          každá má na výběr ze tří možností.
        </div>
        <Button
          variant="success"
          size="sm"
          onClick={() => start({ data, state, setData, setState })}
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          Začít
        </Button>
      </div>
    );
  } else if (data.startTs && !data.endTs) {
    const startTime = new Date(data.currentQuestion.startTs).getTime();
    const currentTime = new Date(data.currentQuestion.currentTs).getTime();
    const difference = startTime + 28000 - currentTime;
    const progress = difference > 0 ? (difference / 30000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "32px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div style={{ fontSize: "40px", paddingTop: "48px" }}>
          {data.questionList[data.currentQuestion.question].question}
        </div>
        <div style={{ fontSize: "40px", padding: "48px 0" }}>
          {data.questionList[data.currentQuestion.question].answerList.map(
            (answer, index) => {
              return (
                <div key={index}>
                  <strong>{["a", "b", "c"][index]})</strong> {answer}
                </div>
              );
            }
          )}
        </div>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button
            variant={"primary"}
            size="sm"
            onClick={() => {
              nextQuestion({ interval, setState, setData });
            }}
          >
            Další otázka
          </Button>
          <Button
            size={"sm"}
            variant={"danger"}
            onClick={() => clear({ setState, setData })}
          >
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
      </div>
    );
  } else {
    const resultMap = {};
    data.answerList.forEach((answer) => {
      const isAnswerCorrect =
        answer.answer === data.questionList[answer.question].correctAnswer;
      if (!resultMap[answer.userName]) resultMap[answer.userName] = 0;
      if (isAnswerCorrect) resultMap[answer.userName] += 1;
    });
    const resultList = [];
    for (let key in resultMap) {
      resultList.push({ userName: key, value: resultMap[key] });
    }
    resultList.sort((a, b) => {
      if (a.value < b.value) return 1;
      if (a.value > b.value) return -1;
      else return 0;
    });
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <Card
          style={{
            width: "100%",
            marginBottom: "16px",
            boxShadow: "4px 8px 4px gold",
          }}
        >
          <Card.Header>
            <Icon
              style={{
                color: "gold",
                marginRight: "8px",
              }}
              path={mdiMedalOutline}
              size={2}
            />
            <span style={{ fontSize: "32px" }}>Výsledek</span>
          </Card.Header>
          <ListGroup className="list-group-flush">
            {resultList.map((result) => {
              return (
                <ListGroup.Item style={{ fontSize: "24px" }}>
                  {result.userName} - {result.value}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card>
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "64px",
            zIndex: "2000",
          }}
        >
          <Button size={"sm"} onClick={() => clear({ setState, setData })}>
            <Icon size={0.7} path={mdiCancel} /> Clear game
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            paddingTop: "64px",
          }}
        >
          {data.questionList.map((question, index) => {
            return (
              <div key={index}>
                <Card
                  style={{
                    marginBottom: "16px",
                    boxShadow: "4px 8px 8px #aaaaaa",
                  }}
                >
                  <Card.Header
                    style={{ fontSize: "24px", background: "lightpink" }}
                  >
                    {question.question}
                  </Card.Header>
                  <ListGroup className="list-group-flush">
                    {question.answerList.map((answerData, index) => {
                      return (
                        <ListGroup.Item
                          key={index}
                          style={{
                            fontSize: "24px",
                            background:
                              index === question.correctAnswer
                                ? "#75b798"
                                : undefined,
                          }}
                        >
                          {answerData}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return children;
}

function getUserChildren({
  userContext,
  data,
  interval,
  state,
  setData,
  setState,
  currentAnswerCache,
  setCurrentAnswerCache,
}) {
  let children;
  if (!data.question && !data.userAnswerList) {
    children = (
      <div
        style={{ display: "block", paddingTop: "128px", textAlign: "center" }}
      >
        <Icon size={5} path={mdiTimerPlayOutline} />
        <div style={{ fontSize: "32px", padding: "32px 0" }}>
          Kvíz ještě nezačal, ještě chvíli vydrž.
        </div>
      </div>
    );
  } else if (data.question) {
    const startTime = new Date(data.startTs).getTime();
    const currentTime = new Date(data.currentTs).getTime();
    const difference = startTime + 28000 - currentTime;
    const progress = difference > 0 ? (difference / 30000) * 100 : 0;
    children = (
      <div style={{ display: "block", paddingTop: "64px" }}>
        <div style={{ fontSize: "28px", paddingTop: "48px" }}>
          {data.question}
        </div>
        {progress > 0 ? (
          <ProgressBar variant={"info"} now={progress} />
        ) : (
          <div
            style={{ color: "#dc3545", fontSize: "18px", textAlign: "center" }}
          >
            čas vypršel
          </div>
        )}
        <div style={{ display: "block", padding: "48px 0" }}>
          {data.answerList.map((answer, index) => {
            return (
              <Button
                key={data.questionIndex + "_" + index}
                style={{ textAlign: "left", fontSize: "28px", width: "100%" }}
                onClick={(e) => {
                  answerQuestion({
                    setState,
                    setData,
                    userContext,
                    question: data.questionIndex,
                    answer: index,
                  });
                  setCurrentAnswerCache(index);
                  e.target.blur();
                }}
                disabled={difference <= 0}
                active={false}
                variant={currentAnswerCache === index ? "primary" : "light"}
              >
                <strong>{["a", "b", "c"][index]})</strong> {answer}
              </Button>
            );
          })}
        </div>
      </div>
    );
  } else {
    children = getUserAnswerList({ data });
  }
  return children;
}

function getUserAnswerList({ data }) {
  let correctAnswerSum = 0;
  return (
    <div style={{ display: "block", paddingTop: "64px" }}>
      {data.userAnswerList.map((question, index) => {
        if (question.correct) correctAnswerSum += 1;
        return (
          <div key={index}>
            <Card
              style={{ marginBottom: "16px", boxShadow: "4px 8px 8px #aaaaaa" }}
            >
              <Card.Header>
                <Icon
                  style={{
                    color: question.correct ? "#75b798" : "#dc3545",
                    marginRight: "8px",
                  }}
                  path={question.correct ? mdiCheckBold : mdiCloseThick}
                  size={1}
                />
                <span style={{ fontSize: "24px" }}>{question.question}</span>
              </Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroup.Item style={{ fontSize: "24px" }}>
                  {question.answer}
                </ListGroup.Item>
                {!question.correct &&
                  (question.userAnswer ? (
                    <ListGroup.Item style={{ background: "#FF9A98" }}>
                      <i style={{ color: "grey" }}>Tvá odpověď:</i>{" "}
                      <span style={{ fontSize: "24px" }}>
                        {question.userAnswer}
                      </span>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item style={{ background: "#FF9A98" }}>
                      ---
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card>
          </div>
        );
      })}
      <div style={{ fontSize: "24px" }}>
        Tvůj výsledek: {<span>{correctAnswerSum}</span>} /{" "}
        {<span>{data.userAnswerList.length}</span>}
      </div>
    </div>
  );
}

async function loadData({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`eighties/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        userType: userContext.user.userType,
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function clear({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`eighties/clear`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: "Celošek19751203",
        userType: "admin",
      }),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function start({ setState, setData, userContext }) {
  try {
    setState("pending");
    const res = await fetch(`eighties/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function nextQuestion({ setState, setData }) {
  try {
    setState("pending");
    const res = await fetch(`eighties/nextQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const body = await res.json();
    setData(body);
    setState("ready");
  } catch (e) {
    console.log(e);
  }
}

async function answerQuestion({ userContext, question, answer }) {
  try {
    await fetch(`eighties/answerQuestion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userContext.user.userName,
        question,
        answer,
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

export default Eighties;
